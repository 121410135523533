import React from 'react';
import {
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { Link, StaticQuery, graphql } from 'gatsby';

const Sidebar = () => (
  <StaticQuery
    query={graphql`
      query SidebarQuery {
        allDatoCmsProject {
          distinct(field: category___slug)
        }
        allDatoCmsCategory(sort: {fields: name}) {
          edges {
            node {
              slug
              name
            }
          }
        }
      }

    `}
    render={data => (
      <aside className="sidebar mt-5 mt-sm-0">
        <h4>Tipologia</h4>
        <ListGroup flush>
          {
          data.allDatoCmsCategory.edges
            .filter(category => data.allDatoCmsProject.distinct.includes(category.node.slug))
            .map(item => (
              <ListGroupItem key={item.node.slug}>
                <Link to={`/progetti/tipologia/${item.node.slug}`}>
                  {item.node.name}
                </Link>
              </ListGroupItem>
            ))
        }
        </ListGroup>
      </aside>
    )}
  />
);

export default Sidebar;
