import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
} from 'reactstrap';

const PageHeader = ({
  title,
  description,
}) => (
  <div className="page-header section-bg">
    <Container>
      <div className="content-center d-flex justify-content-center align-items-center flex-column">
        <h2 className="h1 mb-1">{title}</h2>
        <p className="d-none d-sm-block text-sm mb-0">{description}</p>
      </div>
    </Container>
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};
PageHeader.defaultProps = { description: undefined };


export default PageHeader;
