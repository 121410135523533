import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardImg,
  CardImgOverlay,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Link } from 'gatsby';

const Project = ({ project }) => (
  <Card className="bg-dark text-white mb-2 mb-sm-5" key={project.id}>
    <CardImg width="100%" src={project.gallery.length > 0 ? project.gallery[0].sizes.src : 'https://placeimg.com/800/300/nature'} alt={project.name} />
    <CardImgOverlay className="d-flex align-items-end">
      <CardBody>
        <CardText tag="h6">{project.category.map(c => c.name).join(', ')}</CardText>
        <CardTitle tag="h4">{project.name}</CardTitle>
        <Link to={`/progetti/${project.slug}`} className="stretched-link" />
      </CardBody>
    </CardImgOverlay>
  </Card>
);

Project.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    slug: PropTypes.string,
    category: PropTypes.shape({
      name: PropTypes.string,
    }),
    gallery: PropTypes.array,
  }).isRequired,
};
export default Project;
