import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import PageHeader from '../components/Sections/PageHeader';
import Sidebar from '../components/Sections/Sidebar';
import Project from '../components/Sections/Project';

const IndexPage = ({
  data: { allDatoCmsProject: projects, datoCmsCategory: category },
  pageContext,
}) => (
  <Layout>
    <SEO title={`Progetti di ${category.name}`} />
    <PageHeader title={`Progetti di ${category.name}`} />
    <div id="page" className="section">
      <Container>
        <Row>
          <Col sm={{ size: 8, order: 2 }}>
            {projects.edges.map(project => (
              <Project key={project.node.slug} project={project.node} />
            ))}
            {pageContext.currentPage < pageContext.numPages
              ? (
                <div className="mt-5 text-right">
                  <Link to={`/progetti/tipologia/${pageContext.category}/${pageContext.currentPage + 1}`}>
                    <Button outline color="primary" type="button" className="btn-icon">
                    Meno recenti
                      {' '}
                      <i className="icon-arrow-right" />
                    </Button>
                  </Link>
                </div>
              ) : ''
            }
          </Col>
          <Col sm={{ size: 4, order: 1 }}>
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query CategoryQuery($skip: Int!, $limit: Int!, $category: String!) {
    datoCmsCategory(slug: {eq: $category}) {
      name
    }
    allDatoCmsProject(limit: $limit, skip: $skip, sort: { fields: [position], order: ASC }, filter: {category: {elemMatch: {slug: {eq: $category}}}}) {
      edges {
        node {
          id
          name
          slug
          description
          category {
            name
            slug
          }
          gallery {
            sizes(imgixParams: {fm: "jpg", crop: "focalpoint", fit: "crop", h: "300", w: "800"}, maxWidth: 800, maxHeight: 300) {
              src
            }
          }
        }
      }
    }
  }
`;
